<template>
  <div class="c-modal">
    <div class="c-container">
      <a @click="$emit('close')">close</a>
      <h5>Report auction result</h5><br>
      <form @submit.prevent>
        <p><strong>Address: </strong>{{ this.listing.address }}</p><br>
        <p>Sold for:</p><br>
        <input type="currency" v-model.number="auctionResult" />
        <!-- ADD VALIDATION ON THIS INPUT -->
        <button @click="reportResult()" :disabled="auctionResult == 0" class="button">Submit</button>
      </form>
    </div>
    <div id="ReadResult"></div>
  </div>
</template>

<script>

export default {

  props: ['listing'],
  data() {
    return {
      auctionResult: 0,
    }
  },
  methods: {

    reportResult() {

      if (this.auctionResult) {
        console.log("Setting result for " + this.listing.id + ": " + this.auctionResult)

        this.$store.dispatch('reportResult', {
              listingId: this.listing.id,
              address: this.listing.address,
              auctionResult: this.auctionResult,
          })
        this.auctionResult = 0
      }
      // close modal
      this.$emit('close')
    },

  }
}
</script>
