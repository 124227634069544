<template>
    <div>
        <div v-if="statusToggle=='Live'">
            <div v-if="userCurrentBid"> 
                <p>Your current bid: {{ userCurrentBid.bidValue | formatCurrency }}</p>
                <p>Your bid count: {{ userCurrentBid.bidCount }}</p><br>
                <!-- <p>Total bids: {{ totalBidCount }} -->
                <!-- <p>Active bidders: {{ countActiveBidders }}</p><br> -->
                <form @submit.prevent>
                    <button @click="placeBid()" class="button">Update bid!</button>
                </form>
            </div>
            <div v-else>
                <p>You haven't bid yet!</p><br>
                <!-- <p>Active bidders: {{ countActiveBidders }}</p><br> -->
                <form @submit.prevent>
                    <button @click="placeBid()" class="button">Bid now!</button>
                </form>
            </div>
        </div>
        <div v-else-if="statusToggle=='Pending'">
            <div v-if="userCurrentBid">
                <p>Your final bid: {{ userCurrentBid.bidValue | formatCurrency }}</p><br>
            </div>
            <div v-else>
                <p>You did not bid on this listing.</p><br>
            </div>
            <div>
                <form @submit.prevent>
                    <button @click="addResult()" class="button">Report result</button>
                </form>
            </div>
        </div>
        <div v-else-if="statusToggle=='Results'">
            <div>
                <p>Sold for: {{ this.listing.auctionResult | formatCurrency }}</p>
                <div v-if="userCurrentBid">
                    <p>Your final bid: {{ userCurrentBid.bidValue | formatCurrency }}</p><br>
                    <p>Your accuracy score: {{ userFinalScore | formatPercent }}</p>
                </div>
                <div v-else>
                    <br><p>You did not bid on this listing.</p>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>

import { mapState } from 'vuex'

export default {
    props: ['listing', 'statusToggle'],
    // data(): {return},
    computed: {
        ...mapState(['userId', 'userBidHistory', 'scoreHistory']),
        // totalBidCount() {

        //     return this.userBidHistory.length
        // },
        // countActiveBidders() {
        //     const uniqueBidders = [...new Set(this.userBidHistory.map(i => i.userId))] // need totalBidHistory instead
        //     return uniqueBidders
        // },
        userCurrentBid() {
            try {
                let userCurrentBid = this.userBidHistory.find(bid => { return bid.listingId === this.listing.id && bid.isCurrentBid === true })
                //console.log("Is current bid? "+ userCurrentBid.isCurrentBid + " - value: " + userCurrentBid.bidValue)
                return userCurrentBid
            } catch {
                console.log("Appear to be no current bids")
            }
            return undefined
        },
        userFinalScore() {
            // console.log("userFinalScore(): user id: " + this.userId)
            // console.log("scoreHistory entries: " + this.scoreHistory.length)
            try {
                let userFinalScoreEntry = this.scoreHistory.find(score => { return score.listingId === this.listing.id && score.userId === this.userId })
                return userFinalScoreEntry.score
            } catch {
                console.log("Error finding user final score")
            }
            return undefined
        }
        // existsUserBidOnListing() {
        //     if (this.userCurrentBid) {
        //         return true
        //     }
        //     return false
        // },
        // userBidsOnThisListing() {
        //     let bids = []
        //     try {
        //         this.userBidHistory.forEach(userBid => {
        //             if (userBid.listingId == this.listing.id) {
        //                 bids.push(userBid)
        //             }
        //         })
        //     } catch {
        //         console.log("userBidHistory appears to be empty")
        //     }
        //     console.log("BidButtons found " + bids.length + " user bids on this property")
        //     return bids
        // },
        // countUserBidsOnThisListing() {
        //     return this.userBidsOnThisListing.length
        // },
        // currentBidOnListing() {
        //     // const currentBid = this.userBidsOnThisListing.slice(0).sort(
        //     //     function(x, y) { return y.bidCount - x.bidCount })[0]
            
        //     const currentBid = this.userBidsOnThisListing.find(bid => bid.isCurrentBid === true )
        //     if (currentBid) {
        //         return currentBid.bidValue
        //     } else {
        //         return "Not defined"
        //     }
            
        // },
    },
    methods: {
        placeBid () { this.$emit('placeBid', this.listing) },
        addResult () { this.$emit('addResult', this.listing) }
    },
    filters: {
        formatCurrency(val) {
            if (typeof val !== "number") {
                console.log("not a number")
                return val
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AUD',
                minimumFractionDigits: 0
            })
            return formatter.format(val)
        },
        formatPercent(val) {
            if (typeof val !== "number") {
                console.log("not a number")
                return val
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'percent',
                maximumFractionDigits: 2
            })
            return formatter.format(val)
        }
    },
}

</script>