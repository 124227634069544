import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import { doc, setDoc, getDoc, addDoc, updateDoc, getDocs, query, where, onSnapshot, orderBy } from '@firebase/firestore'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from '@firebase/auth'
import { getStorage, ref, getDownloadURL } from '@firebase/storage'
import router from '../router/index'
import { DocumentSnapshot } from '@firebase/firestore'

Vue.use(Vuex)

// realtime firebase

// const qp = query(fb.postsCollection, orderBy('createdOn', 'desc'))
// const sub_qp = onSnapshot(qp, (orderedSnapshot) => {
//   console.log("Detected new post. Refreshing posts ...")
//   let postsArray = []
//   orderedSnapshot.forEach( (docItem) => {
//     let post = docItem.data()
//     post.id = docItem.id
//     postsArray.push(post)
//   })
//   store.commit('setPosts', postsArray)
// })

const ql = query(fb.listingsCollection, orderBy('auctionDate', 'asc'))
// add query for bids by user ID
const sub_ql = onSnapshot(ql, (orderedSnapshot) => {
  //console.log("Detected new listing. Refreshing listings ...")
  let listingsArray = []
  orderedSnapshot.forEach( (docItem) => {
    let listing = docItem.data()
    listing.id = docItem.id
    // listing.user_bid = get listing id from user bids database
    listingsArray.push(listing)
  })
  console.log("Replicated " + listingsArray.length + " listings.")
  store.commit('setListings', listingsArray)
})

const qc = query(fb.competitionsCollection, orderBy('title', 'desc'))
const sub_qc = onSnapshot(qc, (orderedSnapshot) => {
  //console.log("Detected new or updated competition. Refreshing competitions dataset ...")

  let compsArray = []
  orderedSnapshot.forEach( (docItem) => {
    let comp = docItem.data()
    comp.id = docItem.id
    compsArray.push(comp)
  })
  console.log("Replicated " + compsArray.length + " competitions.")
  store.commit('setCompetitions', compsArray)
})

const qs = query(fb.userScoresCollection, orderBy('userId', 'desc'))
const sub_qs = onSnapshot(qs, (orderedSnapshot) => {
  //console.log("Detected new user scores. Refreshing scores dataset ...")

  let scoresArray = []
  orderedSnapshot.forEach( (docItem) => {
    let score = docItem.data()
    score.id = docItem.id
    scoresArray.push(score)
  })
  console.log("Replicated " + scoresArray.length + " scores.")
  store.commit('setScoreHistory', scoresArray)
})

const qpu = query(fb.usersCollection, where("visible", "==", "public"))
const sub_qpu = onSnapshot(qpu, (querySnapshot) => {
  console.log("Refreshing public users dataset ...")
  let publicUsersArray = []
  querySnapshot.forEach( (docItem) => {
    let user = docItem.data()
    user.id = docItem.id
    publicUsersArray.push(user)
  })
  store.commit('setPublicUsers', publicUsersArray)
})

const quFr = query(fb.userFriendsCollection, orderBy('Name', 'desc'))
const sub_quFr = onSnapshot(quFr, (orderedSnapshot) => {
  console.log("Detected new friend connection. Refreshing friends dataset ...")
  // TODO: update this to create two arrays: one for friends of the user, one for follows by the user (not yet friends).
  let friendsArray = []
  orderedSnapshot.forEach( (docItem) => {
    let friend = docItem.data()
    friend.id = docItem.id
    friendsArray.push(friend)
  })
  store.commit('setFriends', friendsArray)
})

const quFo = query(fb.userFollowsCollection, orderBy('Name', 'desc'))
const sub_quFo = onSnapshot(quFo, (orderedSnapshot) => {
  console.log("Detected new follow connection. Refreshing follows dataset ...")
  // TODO: update this to create two arrays: one for friends of the user, one for follows by the user (not yet friends).
  let followsArray = []
  orderedSnapshot.forEach( (docItem) => {
    let follow = docItem.data()
    follow.id = docItem.id
    followsArray.push(follow)
  })
  store.commit('setFollows', followsArray)
})

// create Vuex store object and properties
const store = new Vuex.Store({
  state: {
    userId: [],
    userProfile: {},
    posts: [],
    listings: [],
    userBidHistory: [],
    scoreHistory: [],
    competitions: [],
    publicUsers: [],
    friends: [],
    follows: [],
  },
  mutations: {
    setUserId(state, val) {
      state.userId = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    // setPerformingRequest(state, val) {
    //   state.performingRequest = val
    // },
    setPosts(state, val) {
      state.posts = val
    },
    setListings(state, val) {
      state.listings = val
    },
    setCompetitions(state, val) {
      state.competitions = val
    },
    setPublicUsers(state, val) {
      state.publicUsers = val
    },
    setFriends(state, val) {
      state.friends = val
    },
    setFollows(state, val) {
      state.follows = val
    },
    setUserBidHistory(state, val) {
      state.userBidHistory = val
      console.log("Committed user bid history with no. entries = " + state.userBidHistory.length)
    },
    addToUserBidHistory(state, val) {
      state.userBidHistory.push(val)
      console.log("Added bid to history, now with no. entries = " + state.userBidHistory.length)
    },
    setScoreHistory(state, val) {
      state.scoreHistory = val
    },
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await signInWithEmailAndPassword(fb.auth, form.email, form.password)

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)

      // load user's bid history and set in state
      dispatch("downloadUserBidHistory")
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await createUserWithEmailAndPassword(fb.auth, form.email, form.password)

      // create user object in userCollections
      await setDoc(doc(fb.usersCollection, user.uid), {
        name: form.name,
        title: form.title
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await getDoc(doc(fb.usersCollection, user.uid))

      // set user profile in state
      commit('setUserId', user.uid)
      commit('setUserProfile', userProfile.data())

      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async logout({ commit }) {
      // log user out
      await signOut(fb.auth)

      // clear user data from state
      commit('setUserId', [])
      commit('setUserProfile', {})

      // redirect to login view
      router.push('/login')
    },
    async createListing({ state, commit }, listingDetails) {
      // create listing in firebase
      const doc = await addDoc(fb.listingsCollection, {
        createdOn: new Date(),
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,

        // upload image to firebase storage and get link
        // (instruction currently issued by addListingModal)

        // save remaining details to firestore record
        listingUrl: listingDetails.listingUrl,
        imageUrl: listingDetails.imageUrl,
        imagePath: listingDetails.imagePath,
        address: listingDetails.address,
        auctionDate: listingDetails.auctionDate,
        propType: listingDetails.propType,
        bed: listingDetails.bed,
        bath: listingDetails.bath,
        car: listingDetails.car,
        EER: listingDetails.EER,
        blockSize: listingDetails.blockSize,
        houseSize: listingDetails.houseSize,
        UV: listingDetails.UV,
        status: listingDetails.status,
        auctionResult: -1,
      })
      console.log("Added new listing to firestore: " + doc.id)
    },

    async reportResult({ state, commit }, thisResult) {
      console.log("Received request to update listing id " + thisResult.listingId + ", address: " + thisResult.address + " with result: " + thisResult.auctionResult)
      // set AuctionResult to listing.AuctionResult, and change status from Pending to Result, where id = listing.id
      await updateDoc(doc(fb.listingsCollection, thisResult.listingId), {
        auctionResult: thisResult.auctionResult,
        status: "Results"
      })
      // TEMP LOCATION for rules engine - migrate to Firebase functions once working

      console.log("Calculating final scores for property: " + thisResult.address)

      // 1. Get active players and their current bid for the finalised listing
      const qActiveBids = query(fb.userBidsCollection, where("listingId", "==", thisResult.listingId), where("isCurrentBid", "==", true))
      const activeBids = await getDocs(qActiveBids)
      console.log("Found active bids: " + activeBids.size)
      
      // 2. Calculate score for each player
      activeBids.forEach( (rec) => {
        let bid = rec.data()
        console.log("Bidder: " + bid.userId + ", bid: " + bid.bidValue)
        let accuracyScore = 0
        if (bid.bidValue <= thisResult.auctionResult) {
          accuracyScore = bid.bidValue / thisResult.auctionResult
        }
        console.log("Accuracy score: " + accuracyScore)

        // 3. Commit score to results collection 
        addDoc(fb.userScoresCollection, {
          listingId: thisResult.listingId,
          userId: bid.userId,
          bidValue: bid.bidValue,
          score: accuracyScore
        })

      })
    },
    async refreshListingPendingStatus() {
      console.log("Refreshing pending listings ...")
      const today = new Date()
      let countExpired = 0

      const qLiveListings = query(fb.listingsCollection, where('status', '==', "Live"))

      const liveListingDocs = await getDocs(qLiveListings)

      liveListingDocs.forEach( (rec) => {
        let listing = rec.data()
        
        if (listing.auctionDate.toDate() <= today) {
          console.log("Found expired listing: AuctionDate = " + listing.auctionDate.toDate() + ", today = " + today)
          countExpired++
          updateDoc(doc(fb.listingsCollection, rec.id), {
            status: "Pending"
          })
          console.log("Record updated")
        }
      })
      console.log("Updated " + countExpired + " listings to status = Pending.")
    
    },
    //load or update user's bid history
    async downloadUserBidHistory({ commit }) {
      const userId = fb.auth.currentUser.uid
      const qubh = query(fb.userBidsCollection, where('userId', '==', userId))
      console.log("Downloading bid history for user: " + userId)
      
      let bidHistoryArray = []
      const download = await getDocs(qubh)

      download.forEach( (docItem) => {
        let userBid = docItem.data()
        userBid.id = docItem.id
        bidHistoryArray.push(userBid)
      })
      //console.log("Downloaded user's bid history with no. entries = " + bidHistoryArray.length)
      commit('setUserBidHistory', bidHistoryArray)
    },

    async addUserBid({ commit }, thisBid) {
    
      // check for previous bid(s)
      const userId = fb.auth.currentUser.uid
      const qPrev = query(fb.userBidsCollection, where('userId', '==', userId), where('listingId', '==', thisBid.listingId))
      const prevBids = await getDocs(qPrev)
      console.log("Found this many previous user bids for this property: " + prevBids.size)

      // if previous bids exist, get the previous bid count and unset the current bid flag
      let mostRecentBids = prevBids.docs.filter(bid => { return bid.data().isCurrentBid === true } )
      console.log("Found this many most recent bids: " + mostRecentBids.length)

      let prevBidCount = 0
      if (mostRecentBids.length == 1 ) {
        console.log("Found 1 previous current bid")
        let mostRecentBid = mostRecentBids[0]
        console.log(mostRecentBid.id)
        prevBidCount = mostRecentBid.data().bidCount
        await updateDoc(doc(fb.userBidsCollection, mostRecentBid.id), {
          isCurrentBid: false
        })
      } else if (mostRecentBids.length == 0) {
        console.log("Found no previous current bids")
        prevBidCount = 0
      } else {
        console.log("ERROR: Found more than one previous current bid")
        return "ERROR"
      }

      // create new bid record
      console.log("Adding new bid: ...")
      const newBid = {
        createdOn: new Date(),
        bidValue: thisBid.bidValue,
        userId: fb.auth.currentUser.uid,
        listingId: thisBid.listingId,
        bidCount: prevBidCount + 1,
        isCurrentBid: true
      }
      await addDoc(fb.userBidsCollection, newBid)
      console.log("Added new bid with counter = " + newBid.bidCount)

      this.dispatch("downloadUserBidHistory")
    },

    async logReferral({ state, commit }, friendEmail) {
      // create listing in firebase
      await addDoc(fb.referralsCollection, {
        email: friendEmail,
        referredBy: fb.auth.currentUser.uid,
        referredOn: new Date()
      })
    },

    async createNewComp({ state, commit }, compDetails) {
      // create listing in firebase
      await addDoc(fb.competitionsCollection, {
        createdOn: new Date(),
        createdBy: fb.auth.currentUser.uid,
        title: compDetails.title,
        description: compDetails.description,
        type: compDetails.type,
        duration: compDetails.duration,
        scope: compDetails.scope,
        structure: compDetails.structure,
        playerCap: compDetails.playerCap,
        stake: compDetails.stake,
        invitations: compDetails.invitations
      })
    },

    // async createPost({ state, commit }, post) {
    //   // create post in firebase
    //   await addDoc(fb.postsCollection, {
    //     createdOn: new Date(),
    //     content: post.content,
    //     userId: fb.auth.currentUser.uid,
    //     userName: state.userProfile.name,
    //     comments: 0,
    //     likes: 0
    //   })
    // },
    // async likePost ({ commit }, post) {
    //   const userId = fb.auth.currentUser.uid
    //   const docId = `${userId}_${post.id}`

    //   // check if user has liked post (can only like once)
    //   const docPostLike = await getDoc(doc(fb.likesCollection, docId))
    //   if (docPostLike.exists()) { return }

    //   // otherwise, create like against post for user
    //   console.log()
    //   await setDoc(doc(fb.likesCollection, docId), {
    //     postId: post.id,
    //     userId: userId
    //   })

    //   // update post likes count
    //   await updateDoc(doc(fb.postsCollection, post.id), {
    //     likes: post.likesCount + 1
    //   })
    // },
    // async createComment ({ state, commit }, postComment) {
    //   //create comment in firebase
    //   console.log("Received comment to add: " + postComment.comment)
    //   await addDoc(fb.commentsCollection, {
    //     createdOn: new Date(),
    //     content: postComment.comment,
    //     postId: postComment.post.id,
    //     userId: fb.auth.currentUser.uid,
    //     userName: state.userProfile.name,
    //   })
    //   // update comment count on post
    //   await updateDoc(doc(fb.postsCollection, postComment.post.id), {
    //     comments: parseInt(postComment.post.comments) + 1
    //   })
    // },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      const userRef = await updateDoc(doc(fb.usersCollection, userId), {
        name: user.name,
        description: user.description
      })

      dispatch('fetchUserProfile', { uid: userId })

      // // update all posts by user
      // const qPosts = query(fb.postsCollection, where('userId', '==', userId))
      // const postDocs = await getDocs(qPosts)
      // postDocs.forEach(doc => {
      //   updateDoc(doc(fb.postsCollection, doc.id), {
      //     userName: user.name
      //   })
      // })

      // // update all comments by user
      // const qComments = query(fb.commentsCollection, where('userId', '==', userId))
      // const commentDocs = await getDocs(qComments)
      // commentDocs.forEach(doc => {
      //   updateDoc(doc(fb.commentsCollection, doc.id), {
      //     userName: user.name
      //   })
      // })
    },
    async updatePreferences({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
    
      const userRef = await updateDoc(doc(fb.usersCollection, userId), {
        visible: user.visible,
        followable: user.followable,
        friendable: user.friendable
      })

      dispatch('fetchUserProfile', { uid: userId })
    }, 

    async uploadImage( { commit }, url) {


      const filename = url.substring(url.lastIndexOf("/"))
      console.log("Filename: " + filename)
  
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function() {
        console.log("Trying storage")
        // const storage = getStorage();
        // const storageRef = ref(storage, filename)
        const storageRef = getStorage().ref(filename).put(xhr.response)
        console.log(storageRef.snapshot.ref.getDownloadURL())
      }
      xhr.open('GET', url)
      xhr.send()
    }

  }
})



export default store
