<template>
  <div class="p-modal">
    <div class="p-container">
      <a @click="$emit('close')">close</a>
      <h5>Place your bid!</h5><br>
      <form @submit.prevent>
        <!-- URL: <input v-model.trim="listingURL" />
        Address: <input v-model.trim="listingAddress" />
        Auction Date: <input v-model.trim="listingAuctionDate" /> -->
        <!-- <Slider 
            v-model="this.userListingBid"
            :min="0" 
            :max="2000000" 
            :step="5000"
            :format="{'prefix' : '$', 'decimals' : 0, 'thousand' : ',' }"
        /> -->
        <img :src="listing.imageUrl"/>
        <p><strong>Address: </strong>{{ this.listing.address }}</p><br>
        <p>Enter bid:</p>
        <span><input type="range" min="0" max="2000000" step="5000" v-model.number="bidValue"/><p>{{ bidValue | formatCurrency }}  </p></span>
        <button @click="addUserBid()" :disabled="bidValue == 0" class="button">Submit</button>
      </form>
    </div>
    <div id="ReadResult"></div>
  </div>
</template>

<script>
// import { listingsCollection, auth } from '@/firebase'
import { mapState } from 'vuex'
import moment from 'moment'
// import Slider from '@vueform/slider/dist/slider.vue2.js'

export default {
  // components: { Slider, },
  props: ['listing'],
  data() {
      return {
      // listingId: this.selectedListing.id,
      // listingAddress: this.selectedListing.Address,
      bidValue: 0,
      value: 0,
      }
  },
  computed: {
      ...mapState(['userProfile'])
  },
  methods: {
      addUserBid() {
          console.log("Received bid input: " + this.bidValue)
          console.log("For listing: " + this.listing.address)
          console.log("with id = " + this.listing.id)

          console.log("type of bidValue = " + typeof this.bidValue)
          
          this.$store.dispatch('addUserBid', {
              listingId: this.listing.id,
              bidValue: this.bidValue,
          })
          // close modal
          this.$emit('close')
      },
  },
  filters: {
    formatCurrency(val) {
      if (typeof val !== "number") {
        console.log("not a number")
        return val
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0
      })
      return formatter.format(val)
    }
  }
}
</script>
