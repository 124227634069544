<template>
  <div class="p-modal">
    <div class="p-container">
      <a @click="$emit('close')">close</a>
      <h5>Create a new auction listing</h5>
      <!-- <h5>Enter a valid link to a live listing (allhomes, ...):</h5> -->
      <form @submit.prevent>
        <!-- <input type="file" ref="doc" @change="readFile()" /> -->
        <span>
          <p>[@dev: so far only works with allhomes]</p>
          <label>Enter URL:</label>
          <input type="url" v-model.trim="listingDetails.listingUrl" :disabled="showPreviewData" />
          <button @click="scrapeListing()" :hidden="showPreviewData || awaitingScrapeResult" class="button" >Import</button>
          <p :hidden="!awaitingScrapeResult">Gathering data from website (this may take 10-15 seconds) ...</p>
        </span>
      </form>
  <!-- check flag to make visible -->
      <div v-if="showPreviewData">
        <h5>Preview</h5>
        <p>Review and edit the details below, then Confirm to create new listing:</p>
        <form @submit.prevent>
          <img :src="listingDetails.imageUrl"/>
          Address:<input v-model.trim="listingDetails.address" />
          Auction:<VueCtkDateTimePicker :minute-interval="15" v-model="listingDetails.auctionDate" type="datetime-local" />
          <table>
            <!-- <tr><th>Attribute</th><th>Value</th></tr> -->
            <tr>
              <td>Type:</td><td><input v-model.trim="listingDetails.propType" /></td>
              <td>EER:</td><td><input v-model.trim="listingDetails.EER" /></td>
            </tr>
            <tr>
              <td>Bed:</td><td><input v-model.trim="listingDetails.bed" /></td>
              <td>Block size:</td><td><input v-model.trim="listingDetails.blockSize" /></td>
            </tr>
            <tr>
              <td>Bath:</td><td><input v-model.trim="listingDetails.bath" /></td>
              <td>House size:</td><td><input v-model.trim="listingDetails.houseSize" /></td>
            </tr>
            <tr>
              <td>Car:</td><td><input v-model.trim="listingDetails.car" /></td>
              <td>UV:</td><td><input v-model.trim="listingDetails.UV" /></td>
            </tr>
          </table>
          <button @click="createListing()" :disabled="listingDetails.listingUrl == ''" class="button">Confirm</button>
          <button @click="saveImage(listingDetails.imageUrl)">Test image upload</button>
          <!-- <button @click="createListing()" :disabled="listingUrl == '' || listingAddress == '' || listingAuctionDate = ''" class="button">Create from form</button> -->
        </form>
      </div>
    </div>
    <div id="ReadResult"></div>
  </div>
</template>

<script>
// import { listingsCollection, auth } from '@/firebase'
// import axios from 'axios'

// import functions from '/firebase'
import { getFunctions, httpsCallable } from '@firebase/functions'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment'

//Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

export default {
  components: {
    VueCtkDateTimePicker,
  },
  props: ['listing'],
  data() {
    return {
      showPreviewData: false,
      awaitingScrapeResult: false,
      listingDetails: {
        listingUrl: '',
        imageUrl: '',
        imagePath: '',
        address: '',
        auctionDate: '',
        propType: '',
        bed: '',
        bath: '',
        car: '',
        EER: '',
        blockSize: '',
        houseSize: '',
        UV: '',
        status: ''
      }
    }
  },
  computed: {
    todaysDate() {
      // today = new Date()
      return moment().format('YYYYMMDD')
    }
  },
  methods: {

    async createListing() {
      // toggleAddListingModal()
      console.log("Triggered createListing")

      // convert from moment to DateTime object just before sending
      // FIX - causing a type error
      this.listingDetails.auctionDate = this.listingDetails.auctionDate.toDate()

      // instruct server to save hero image to Firebase Storage and return reference
      this.listingDetails.imagePath = await this.saveImage(this.listingDetails.imageUrl)
      // const result = await this.saveImage(this.listingDetails.imageUrl)
      console.log("Save image returned: " + this.listingDetails.imagePath)

      // create full new listing record in Firestore DB:
      this.$store.dispatch('createListing', this.listingDetails)
      
      // reset listing details
      for (let item in this.listingDetails) {this.listingDetails[item] = ''}
      console.log("Reset listingDetails: " + JSON.stringify(this.listingDetails))

      this.showPreviewData = false

      // close modal
      this.$emit('close')
    },

    async scrapeListing() {
      this.awaitingScrapeResult = true

      console.log("Requesting page access via server function ...")

      const functions = getFunctions()
      const getListingByURL = httpsCallable(functions, "getListingByURL");
      const serverResult = await getListingByURL({ URL: this.listingDetails.listingUrl })

      if (!serverResult.data.imageUrl) {
        console.log("Appears to have been an error downloading record. Pls check link is valid or supported.")
        return null
      }

      console.log("Server reports: " + serverResult.data.imageUrl)
      // console.log("String date: " + serverResult.data.date)
      // console.log("Got date: " + moment(serverResult.data.date).format('DD MMM yyyy, h:mma'))

      this.listingDetails.imageUrl = serverResult.data.imageUrl ?? '[not found]'
      this.listingDetails.address = serverResult.data.address ?? '[not found]'
      // TODO: Fix the type error warning on the moment/date object
      this.listingDetails.auctionDate = moment(serverResult.data.date, "DDDD DD MMMM h:mma") //.toDate()
      this.listingDetails.propType = serverResult.data.propType ?? '[not found]'
      this.listingDetails.bed = serverResult.data.bed ?? '[not found]'
      this.listingDetails.bath = serverResult.data.bath ?? '[not found]'
      this.listingDetails.car = serverResult.data.car ?? '[not found]'
      this.listingDetails.EER = serverResult.data.EER ?? '[not found]'
      this.listingDetails.blockSize = serverResult.data.blockSize ?? '[not found]'
      this.listingDetails.houseSize = serverResult.data.houseSize ?? '[not found]'
      this.listingDetails.UV = serverResult.data.UV ?? '[not found]'

      this.listingDetails.status = this.getStatusVar(new Date(this.listingDetails.auctionDate))

      this.awaitingScrapeResult = false
      this.showPreviewData = true
    },

    async saveImage(url) {
      const functions = getFunctions()
      const saveImageToDB = httpsCallable(functions, "saveImageToDB");
      const serverResult = await saveImageToDB({ imageUrl: url })

      console.log("Server reports: " + serverResult.data)
      // return "images/" + url.split('/').pop();
      return serverResult.data
    },

    getStatusVar(date) {
      if (date <= moment()) { return 'Pending' } 
      else if (date > moment()) { return 'Live' } 
      else { return null }
    },
    
  }
}
</script>
