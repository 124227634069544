<template>
  <div id="dashboard">
    <transition name="fade">
      <AddListingModal v-if="showAddListingModal" @close="toggleAddListingModal()"></AddListingModal>
    </transition>
    <transition name="fade">
      <PlaceBidModal v-if="showPlaceBidModal" :listing="selectedListing" @close="togglePlaceBidModal()"></PlaceBidModal>
    </transition>
    <transition name="fade">
      <ReportResultModal v-if="showReportResultModal" :listing="selectedListing" @close="toggleReportResultModal()"></ReportResultModal>
    </transition>
    <section>
      <div class="col2">
        
        <div class="action-bar-container">
          <div class="action-bar-child">
            <span v-for="view in viewOptions" :key="view.id">
              <button class="button-toggle" @click="switchView(view.value)" :disabled="selectedView == view.value">{{ view.value }}</button>
            </span>
          </div>
          <div class="action-bar-child">
            <span v-for="status in statusOptions" :key="status.id">
              <button class="button-toggle" @click="switchStatus(status.value)" :disabled="selectedStatus == status.value">{{ status.value }}</button>
            </span>
          </div>
          <div class="action-bar-child">
            <input type="text" v-model="search" placeholder="Search ..." class="search-input-field"/>
            <span class="search-icon">
              <i class="fas fa-search"></i>
            </span>
          </div>
          <div class="action-bar-child">
            <select type="text" v-model="sortBy" placeholder="Sort by ... [TBD]" class="sort-input-field">
              <option value="">All</option>
              <option v-for="f in sortFilters" :key="f.id">{{ f.title }}</option>
            </select>
          </div>
          <div>
            <form @submit.prevent>
              <button @click="toggleAddListingModal()" class="button">Add new auction</button>
            </form>
          </div>
        </div>

        <div v-if="selectedView == 'Map'">
          <p>Under construction</p>
        </div>
        <div v-else>
          <div v-if="listings.length">
            <div :class="[ selectedView + '-layout' ]">
              <div v-for="listing in filteredAndSortedListings" :key="listing.id" class="listing">
                <div :class="[ selectedView + '-container' ]">
                  <div class="grid-child">
                    <img class="image-mini" :src="listing.imagePath" @error="$event.target.src=listing.imageUrl;$event.target.title='url';" tag="this.src"/>
                  </div>
                  <div class="grid-child">
                    <h5><a :href="listing.listingUrl" target="_blank">{{ listing.address }}</a></h5>
                    <!-- <p><a v-bind:href="listing.listingUrl" target="_blank">{{ listing.listingUrl }}</a></p> -->
                    <p><strong>Auction: </strong>{{ listing.auctionDate | formatDate }}</p>
                    <p><strong>[Countdown]: </strong>{{ listing.auctionDate | formatDateFromNow }}</p>
                    <ul>
                      <li>{{ listing.propType || '-' }}</li>
                      <li>{{ listing.bed || '-' }}</li>
                      <li>{{ listing.bath || '-' }}</li>
                      <li>{{ listing.car || '-' }}</li>
                      <li>{{ listing.EER || '-' }}</li>
                    </ul>
                    <ul>
                      <li>{{ listing.blockSize || '-' }}</li>
                      <li>{{ listing.houseSize || '-' }}</li>
                      <li>{{ listing.UV || '-' }}</li>
                    </ul>
                  </div>
                  <div class="grid-child">
                    <bid-buttons :listing="listing" :statusToggle="selectedStatus" :userProfile="userProfile" :userBidHistory="userBidHistory" :scoreHistory="scoreHistory" @placeBid="togglePlaceBidModal" @addResult="toggleReportResultModal">
                    </bid-buttons>
                    <!-- <div v-if="userBidHistory.some(userBid => userBid.listingId == listing.id)"> 
                      <p>Current bid: {{ currentBidOnListing }}</p><br>
                      <form @submit.prevent>
                        <button @click="togglePlaceBidModal(listing)" class="button">Update bid!</button>
                      </form>
                    </div>
                    <div v-else>
                      <p>No bid entered</p><br>
                      <form @submit.prevent>
                        <button @click="togglePlaceBidModal(listing)" class="button">Make a bid!</button>
                      </form>
                    </div> -->
                  </div>
                  <div>
                    <form @submit.prevent>
                      <button class="button">Share</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="no-results">No listings match the current search criteria.</p>
          </div>
        </div>
      </div>
      <!-- <div class="col1">
        <div class="profile">
          <div class="create-post">
            <h5>Add a new auction</h5>
            <p>Can't see an auction that you know is live?</p>
            <p>Create a new entry here:</p>
            <form @submit.prevent>
              <button @click="toggleAddListingModal()" class="button">Add new auction</button>
            </form>
          </div>
        </div>
      </div> -->
    </section>

    <!-- full listing modal -->
    <transition name="fade">
      <div v-if="showFullListingModal" class="p-modal">
        <div class="p-container">
          <a @click="closeListingModal()" class="close">close</a>
          <div class="post"> <!-- update -->
            <h5>{{ fullListing.address }}</h5>
            <span>{{ fullListing.auctionDate | formatDate }}</span>
            <!-- <p>{{ fullPost.content }}</p>
            <ul>
              <li><a>comments {{ fullPost.comments }}</a></li>
              <li><a>likes {{ fullPost.likes }}</a></li>
            </ul> -->
          </div>
          <!-- <div v-show="postComments.length" class="comments">
            <div v-for="comment in postComments" :key="comment.id" class="comment">
              <p>{{ comment.userName }}</p>
              <span>{{ comment.createdOn | formatDate }}</span>
              <p>{{ comment.content }}</p>
            </div>
          </div> -->
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import { listingsCollection } from '@/firebase'
import { mapState } from 'vuex'
import moment from 'moment'
import ToggleSwitch from 'vuejs-toggle-switch'
import AddListingModal from '@/components/AddListingModal'
import ReportResultModal from '@/components/ReportResultModal'
import PlaceBidModal from '@/components/PlaceBidModal'
import BidButtons from '@/components/BidButtons'

export default {
  components: {
    ToggleSwitch,
    AddListingModal,
    ReportResultModal,
    PlaceBidModal,
    BidButtons,
  },
  data() {
    return {
      // listing: {
      // // URL: '',
      // // Address: '',
      // // AuctionDate: '',
      // // userListingBid: 0,
      // },
      showAddListingModal: false,
      showPlaceBidModal: false,
      showReportResultModal: false,
      showFullListingModal: false,
      selectedListing: {},
      fullListing: {},
      // isGridView: true,
      // buttonSwitchViewText: "List view",
      selectedView: "Grid",
      viewOptions: [
        {id: 0, value: "Grid"}, 
        {id: 1, value: "List"}, 
        {id: 2, value: "Map"}
        ],
      selectedStatus: "Live",
      statusOptions: [
        {id: 0, value: "Live"}, 
        {id: 1, value: "Pending"}, 
        {id: 2, value: "Results"}
        ],
      search: '',
      sortBy: '',
      sortFilters: [
        { 
          id: 1,
          value: 7,
          title: 'Coming week'
        },
        { 
          id: 2,
          value: 28,
          title: 'Coming month'
        }
      ],
      // statusToggle: '',
      // toggleSwitchOptions: {
      //   layout: {
      //     color: 'black',
      //     backgroundColor: 'white',
      //     selectedColor: 'white',
      //     selectedBackgroundColor: 'blue',
      //     borderColor: 'white',
      //     fontFamily: 'Open Sans',
      //     fontWeight: 'normal',
      //     fontWeightSelected: 'bold',
      //     // squareCorners: 'false',
      //     noBorder: true
      //   },
      //   size: {
      //     fontSize: 0.8,
      //     height: 1.5,
      //     padding: 0.3,
      //     width: 15
      //   },
      //   items: {
      //     delay: 0.4,
      //     preSelected: 'Live',
      //     disabled: false,
      //     labels: [
      //       {name: 'Live', color: 'white', backgroundColor: 'blue'},
      //       {name: 'Pending', color: 'white', backgroundColor: 'blue'},
      //       {name: 'Results', color: 'white', backgroundColor: 'blue'},
      //     ]
      //   },
      // }
    }
  },
  computed: {
    ...mapState(['userProfile', 'listings', 'userBidHistory', 'scoreHistory']),

    filteredAndSortedListings: function () {
      let result = this.listings
      // Apply status filter first
      if (this.selectedStatus) {
        result = result.filter(listing => listing.status == this.selectedStatus)
      }
      // Apply search bar text filter
      if (this.search) {
        result = result.filter(listing => listing.address.toLowerCase().includes(this.search.toLowerCase()))
      }
      // Apply other sort / filter options
      // TODO
      return result
    }
  },
  methods: {

    toggleAddListingModal() {
      this.showAddListingModal = !this.showAddListingModal
      console.log("AddListingModal toggled to " + this.showAddListingModal)
    },
    toggleReportResultModal(listing) {
      this.showReportResultModal = !this.showReportResultModal
      console.log("ReportResultModal toggled to " + this.showReportResultModal)
      if (this.showReportResultModal) {
        this.selectedListing = listing
        console.log("Selected listing is now: " + this.selectedListing.address)
      } else {
        this.selectedListing = {}
        console.log("Selected listing has been cleared")
      }
    },
    togglePlaceBidModal(listing) {
      this.showPlaceBidModal = !this.showPlaceBidModal
      console.log("PlaceBidModal toggled to " + this.showPlaceBidModal)
      if (this.showPlaceBidModal) {
        this.selectedListing = listing
        console.log("Selected listing is now: " + this.selectedListing.address)
      } else {
        this.selectedListing = {}
        console.log("Selected listing has been cleared")
      }
    },
    switchView(value) {
      this.selectedView = value
    },
    switchStatus(value) {
      this.selectedStatus = value
    },
    // likePost(id, likesCount) {
    //   this.$store.dispatch('likePost', { id, likesCount })
    // },
    async viewListing(listing) {
      const docs = await listingsCollection.where('listingId', '==', listing.id).get()

      // docs.forEach(doc => {
      //   let comment = doc.data()
      //   comment.id = doc.id
      //   this.postComments.push(comment)
      // })

      this.fullListing = listing
      this.showListingModal = true
    },
    closeListingModal() {
      // this.postComments = []
      this.showListingModal = false
    },
    // loadImage(imagePath) {
    //   // fix reference to the right bucket object, import dependencies
    //   const fileRef = bucket.ref().child(imagePath)
    //   fileRef.getDownloadURL()
    //     .then(url => {
    //       this.imageDBurl
    //     })
    // }
  },
  filters: {
    formatDate(val) {
      if (!val) { return '-' }

      let date = val.toDate()
      return moment(date).format('h:mma [|] dddd DD MMMM YYYY')
    },
    formatDateFromNow(val) {
      if (!val) { return '-' }

      let date = val.toDate()
      return moment(date).fromNow()
    },
    trimLength(val) {
      if (val.length < 200) { return val }
      return `${val.substring(0, 200)}...`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
