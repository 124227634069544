<template>
  <header>
    <section>
      <div class="col1">
        <router-link to="/">
          <h3>TopBid demo</h3>
        </router-link>
        <ul class="inline">
          <li>
            <router-link to="/">Properties</router-link>
          </li>
          <!-- <li>
            <router-link to="/Results">Results</router-link>
          </li> -->
          <li>
            <router-link to="/competitions">Competitions</router-link>
          </li>
          <li>
            <router-link to="/social">Social</router-link>
          </li>
          <li>
            <router-link to="/profile">Profile</router-link>
          </li>
          <li v-if="userProfile.admin">
            <router-link to="/admin">ADMIN</router-link>
          </li>
          <li><a @click="logout()">Logout</a></li>
        </ul>
      </div>
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>
