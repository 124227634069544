import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  onSnapshot,
  setDoc,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyBIK-eaRd4X1WlrqHSdmRSbe6HSZ1DfzTw",
  authDomain: "topbid-prototype-v1.firebaseapp.com",
  projectId: "topbid-prototype-v1",
  storageBucket: "topbid-prototype-v1.appspot.com",
  messagingSenderId: "796132115802",
  appId: "1:796132115802:web:0a26163582d40c8e1d9f63",
  measurementId: "G-E4PL4JTX2J"
}

const app = initializeApp(firebaseConfig)

// utils
const db = getFirestore(app)
const auth = getAuth(app)
const functions = getFunctions(app)
const https = httpsCallable(app)
// const bucket = getStorage().bucket()

// collection references
const usersCollection = collection(db, 'users')

const listingsCollection = collection(db, 'listings')
const userBidsCollection = collection(db, 'userBids')
const userScoresCollection = collection(db, 'userScores')

const friendsCollection = collection(db, 'friends')
const competitionsCollection = collection(db, 'competitions')
const referralsCollection = collection(db, 'referrals')

const userFriendsCollection = collection(db, 'userFriends')
const userFollowsCollection = collection(db, 'userFollows')

const postsCollection = collection(db, 'posts')
const commentsCollection = collection(db, 'comments')
const likesCollection = collection(db, 'likes')

// export utils/refs
export {
  db,
  auth,
  functions,
  // bucket,
  usersCollection,

  listingsCollection,
  userBidsCollection,
  userScoresCollection,

  friendsCollection, 
  competitionsCollection,
  referralsCollection,
  userFriendsCollection,
  userFollowsCollection,

  postsCollection,
  commentsCollection,
  likesCollection

}
